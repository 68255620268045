// This component is used to render a custom template for our tooltip directive.
// If you pass the directive a template, it will render it inside this component.
// This allows us to make use of Angular's template rendering capabilities to render the tooltip content.

import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ag1-tooltip-container',
  templateUrl: './ag1-tooltip-container.component.html',
})
export class Ag1TooltipContainerComponent {
  template: TemplateRef<any>;
  @ViewChild('content', { static: true }) content: ElementRef;
}
